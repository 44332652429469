import React, { useState, useMemo, useCallback } from 'react';
import { format } from "date-fns";
import {
    Box,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    TextField,
    Chip,
    Menu,
    MenuItem,
    IconButton,
    makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useGetFormsQuery, useUpdateFormMutation } from "../../store/slices/pardotApiSlice";

const useStyles = makeStyles({
    chips: {
        marginRight: '10px',
        marginTop: '3px'
    },
    smallChip: {
        marginRight: '2px'
    }
});


const Forms = ({ setForm, filter, setFilter }) => {

    const classes = useStyles();

    const { data: forms } = useGetFormsQuery();
    const [page, setPage] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuForm, setMenuForm] = useState(null);

    const handleOpenMenu = (target, form) => {
        setAnchorEl(target);
        setMenuForm(form);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuForm(null);
    };

    const [updateForm, { isLoading: formUpdating }] = useUpdateFormMutation();

    const addTag = useCallback((tag) => {
        updateForm({ formId: menuForm.id, tag, isActive: true });
        handleCloseMenu();
    }, [menuForm?.id, updateForm]);

    const deleteTag = useCallback((formId, tag) => {
        updateForm({ formId, tag, isActive: false });
    }, [updateForm]);


    const tags = useMemo(() => {

        if (!forms)
            return [];

        let arr = forms.map(f => f.tags);

        let list = [...new Set(arr.flat().map(item => item))].sort();

        return list;

    }, [forms]);

    const [selectedTag, setSelectedTag] = useState();

    const activeFilter = useMemo(() => {
        if (filter.length < 3)
            return "";

        setPage(0);

        return filter.toLowerCase();
    }, [filter]);    

    const rowsPerPage = 10;

    const sortedForms = useMemo(() => {
        if (!forms)
            return [];

        let sorted = [...forms];

        return sorted.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);
    }, [forms]);

    const filteredForms = useMemo(() => {

        let filtered = sortedForms;

        if (activeFilter !== "")
            filtered = filtered.filter(f => f.name.toLowerCase().includes(activeFilter) || f.campaignName?.toLowerCase().includes(activeFilter));

        if (selectedTag)
            filtered = filtered.filter(f => f.tags.includes(selectedTag));

        return filtered;

    }, [activeFilter, selectedTag, sortedForms]);

    const displayedForms = useMemo(() => {

        let displayed = filteredForms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return displayed;
    }, [filteredForms, page]);

    const handleTagClick = val => {
        setPage(0);

        if (val === selectedTag)
            setSelectedTag();
        else
            setSelectedTag(val);
    }

    return (
        <>
            <Box>
                <TextField
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    variant="outlined"
                    size="small"
                    label="filter"
                />
            </Box>
            <Box>
                {tags.map(tag =>
                    <Chip
                        className={classes.chips}
                        key={tag}
                        label={tag}
                        onClick={() => handleTagClick(tag)}
                        color={tag === selectedTag ? "primary" : "default"}
                    />
                )}
            </Box>
            {!forms && <h2 style={{ margin: '10px' }}>Loading ...</h2>}
            {forms && forms.length === 0 && <h2 style={{ margin: '10px' }}>No entries</h2>}
            {forms && forms.length > 0 &&
                <Table size="small">
                    <TableHead>
                        <TableRow style={{height:'37px'}}>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>Campaign</TableCell>
                            <TableCell>Updated</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedForms.map(f =>
                            <TableRow key={f.id}>
                                <TableCell>{f.id}</TableCell>
                                <TableCell style={{ cursor: 'pointer' }} onClick={() => { setForm(f); }}>{f.name}</TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        {f.tags.map(t =>
                                            <Chip
                                                key={t}
                                                label={t}
                                                className={classes.smallChip}
                                                size="small"
                                                disabled={formUpdating}
                                                onDelete={() => deleteTag(f.id, t)}
                                            />
                                        )}
                                        {f.tags.length < tags.length &&
                                            <IconButton
                                                size="small"
                                                onClick={(e) => handleOpenMenu(e.currentTarget, f)}
                                                disabled={formUpdating}
                                            >
                                                <AddIcon fontSize="small"/>
                                            </IconButton>
                                           
                                        }
                                    </Box>
                                </TableCell>
                                <TableCell>{f.campaignName}</TableCell>
                                <TableCell>{format(new Date(f.updatedAt), "dd/MM/yy HH:mm")}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                count={filteredForms.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(e, newPage) => setPage(newPage)}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            }
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {menuForm && tags.filter(tag => !menuForm.tags.includes(tag)).map(tag => <MenuItem onClick={() => addTag(tag)}>{tag}</MenuItem>)}
            </Menu>
        </>
    )
};

export default Forms;
