import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import "../../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Box,
    Checkbox,
    makeStyles,
    Button,
    Menu,
    MenuItem,
    Typography,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    ListItemText,
    ListSubheader,
    TablePagination,
} from "@material-ui/core";
import {
    CheckCircleOutline as YesIcon,
    CancelOutlined as NoIcon,
    HelpOutline as UnknownIcon,
    Close as CloseIcon,
    ArrowDropUp,
    ArrowDropDown,
} from "@material-ui/icons";
import {
    useGetJobTitlesQuery,
    useGetUsersQuery,
    useGetProspectsQuery,
    useUpdateJobTitleMutation,
    useUpdateProspectsMutation,
    useGetBDEMappingsQuery,
} from "../../store/slices/pardotApiSlice";

const useStyles = makeStyles({
    container: {
        maxHeight: "100%",
        height: "100%",
        flex: 1,
        marginTop: '5px'
    },
    page: {
        maxHeight: "calc(100vh - 205px)",
    },
    chips: {
        marginRight: '10px',
        marginTop: '3px'
    },
    button: {
        "&:disabled": {
            color: 'rgba(0,0,0,0.87)'
        }
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
    formControl: {
        width: 240
    },
    rowGroup: {
        '& tr:first-child td': {
            borderTop: '2px solid red'
        },
        '& tr:last-child td': {
            borderBottom: '2px solid red',
        },
        '& tr td:first-child': {
            borderLeft: '2px solid red'
        },
        '& tr td:last-child': {
            borderRight: '2px solid red'
        },
        '& tr:first-child td:first-child': {
            borderTopLeftRadius: '5px'
        },
        '& tr:first-child td:last-child': {
            borderTopRightRadius: '5px'
        },
        '& tr:last-child td:first-child': {
            borderBottomLeftRadius: '5px'
        },
        '& tr:last-child td:last-child': {
            borderBottomRightRadius: '5px'
        }
    }
});

const Prospects = ({ form, getFormName, clear, list }) => {

    const classes = useStyles();

    const { data: jobTitles } = useGetJobTitlesQuery();
    const { data: users } = useGetUsersQuery();
    const { data: mappings } = useGetBDEMappingsQuery();
    const [page, setPage] = useState(0);

    const rowsPerPage = 100;

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedProfile, setSelectedProfile] = useState("");
    const [selectedTriage, setSelectedTriage] = useState("");
    const [selectedOwnerIds, setSelectedOwnerIds] = useState([]);

    const [previousDays, setPreviousDays] = useState(1);
    const [taskDays, setTaskDays] = useState(0);

    const { data: prospectGroups, isFetching } = useGetProspectsQuery({ formId: form?.id, listId: list?.id, previousDays });

    const expandedProspects = useMemo(() => {

        if (!prospectGroups)
            return [];

        return prospectGroups.flatMap(p => p.prospects);
    }, [prospectGroups]);

    const [updateJobTitle] = useUpdateJobTitleMutation();
    const [updateProspects, { isLoading: saving }] = useUpdateProspectsMutation();

    const [anchorEl, setAnchorEl] = useState();

    const [sortColumn, setSortColumn] = useState("lastActivity");
    const [sortAscending, setSortAscending] = useState(false);

    useEffect(() => {
        setSelectedIds([]);
    }, [previousDays]);

    const tableHeadings = [
        { name: "", display: true },
        { name: "Name/Email", display: true },
        { name: "Job Title / (SDM)", display: true, },
        { name: "Company", display: true, sortKey: 'company' },
        { name: "Employees", display: true, sortKey: 'employeeProfile' },
        { name: "Score", display: true, sortKey: 'score' },
        { name: "Last Activity / Interaction", display: true, sortKey: 'lastActivity' },
        { name: "Forms", display: !form && !list },
        { name: "Grade", display: true, sortKey: 'formScore' },
        { name: "Triage", display: true, sortKey: 'triage' },
        { name: "SF Activity Date", display: true, sortKey: 'taskDueDate' },
        { name: "Owner", display: true },
        { name: "Recommended", display: true },
    ];

    const toggleSortBy = (th) => {
        if (th.sortKey === sortColumn) {
            setSortAscending(!sortAscending);
        }
        else {
            setSortAscending(true);
            setSortColumn(th.sortKey);
        }
    }

    const user = useCallback(id => {

        if (id === null)
            return null;

        var u = users?.find(x => x.id === id);

        return u || { id, name: id };
    }, [users]);

    const sortedProspectGroups = useMemo(() => {
        if (!prospectGroups)
            return [];

        let sorted = [...prospectGroups];

        let val = sortAscending ? -1 : 1;

        if (sortColumn === 'owner')
            return sorted.sort((a, b) => (user(a.salesforceOwnerId)?.name < user(b.salesforceOwnerId)?.name) ? val : -val);

        return sorted.sort((a, b) => (a[sortColumn] || "") < (b[sortColumn] || "") ? val : -val);

    }, [prospectGroups, sortColumn, sortAscending, user]);

    let filteredProspectGroups = useMemo(() => {

        let filtered = sortedProspectGroups;

        if (selectedTriage)
            filtered = filtered.filter(g => g.prospects.some(p => p.triage === selectedTriage));

        if (selectedProfile)
            filtered = filtered.filter(g => g.prospects.some(p => (p.employeeProfile || "unknown") === selectedProfile));

        //if (selectedStatus)
        //    filtered = filtered.filter(g => g.prospects.some(p => `${p.contactStatus} ${p.contactSubStatus}` === selectedStatus));

        if (selectedStatus)
            filtered = filtered.filter(g => g.prospects.some(p => `${p.accountType || "BLANK"} / ${p.accountStatus || "BLANK"}` === selectedStatus));

        if (selectedOwnerIds.length > 0)
            filtered = filtered.filter(g => g.prospects.some(p => selectedOwnerIds.includes(p.salesforceOwnerId)));

        return filtered;
    }, [sortedProspectGroups, selectedTriage, selectedProfile, selectedStatus, selectedOwnerIds]);

    useEffect(() => {
        setPage(0)
    }, [selectedTriage, selectedProfile, selectedStatus, selectedOwnerIds]);

    const displayedProspectGroups = useMemo(() => {

        let displayed = filteredProspectGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return displayed;
    }, [filteredProspectGroups, page]);

    const setJobTitle = useCallback((title, isSDM) => {
        updateJobTitle({ title: title.toUpperCase(), isSDM });
    }, [updateJobTitle]);

    const sdmIcon = useCallback((title) => {

        if (!jobTitles)
            return null;

        let jobTitle = jobTitles.find(j => j.title.trim() === title?.toUpperCase());

        let icon = jobTitle
            ? jobTitle.isSDM
                ? <YesIcon onClick={() => setJobTitle(title, false)} />
                : <NoIcon onClick={() => setJobTitle(title, true)} />
            : <UnknownIcon onClick={() => setJobTitle(title, false)} />

        return <div style={{ cursor: 'pointer' }}>{icon}</div>;

    }, [jobTitles, setJobTitle]);

    var usersForRole = useCallback((roleId) => {
        if (!users)
            return [];
        return users.filter(u => u.isActive && u.userRoleId === roleId).sort((a, b) => a.name > b.name ? 1 : -1);
    }, [users]);

    const assignUser = useCallback((userId) => {
        closeMenu();

        let updates = selectedIds.map(id => ({ ...expandedProspects.find(p => p.id === id), salesforceOwnerId: userId }));

        updateProspects({ updates, taskDays });

        setSelectedIds([]);

    }, [expandedProspects, selectedIds, taskDays, updateProspects])

    const stageCounts = useMemo(() => {

        let statuses = [...new Set(expandedProspects.map(item => item.triage))].sort();

        let counts = statuses.map(s => ({ triage: s, count: expandedProspects.filter(p => p.triage === s).length }));

        return counts;
    }, [expandedProspects]);

    //const statusCounts = useMemo(() => {

    //    let statuses = [...new Set(expandedProspects.map(item => `${item.contactStatus} ${item.contactSubStatus}`))].sort();

    //    let counts = statuses.map(s => ({ status: s, count: expandedProspects.filter(p => `${p.contactStatus} ${p.contactSubStatus}` === s).length }));

    //    return counts;
    //}, [expandedProspects]);

    const statusCounts = useMemo(() => {

        let statuses = [...new Set(expandedProspects.map(item => `${item.accountType || "BLANK"} / ${item.accountStatus || "BLANK"}`))].sort();

        let counts = statuses.map(s => ({ status: s, count: expandedProspects.filter(p => `${p.accountType || "BLANK"} / ${p.accountStatus || "BLANK"}` === s).length }));

        return counts;
    }, [expandedProspects]);

    const userCounts = useMemo(() => {

        let ownerIds = [...new Set(expandedProspects.map(item => item.salesforceOwnerId))]

        let counts = ownerIds
            .map(id => ({ user: user(id) || { id: null, name: 'UNASSIGNED' }, count: expandedProspects.filter(p => p.salesforceOwnerId === id).length }))
            .sort((a, b) => (a.user.name > b.user.name) || !a.user.id ? 1 : -1);

        return counts;
    }, [expandedProspects, user]);

    const profileCounts = useMemo(() => {

        let profiles = [...new Set(expandedProspects.map(item => item.employeeProfile))].sort();

        let counts = profiles.map(profile => ({ profile: profile, count: expandedProspects.filter(p => p.employeeProfile === profile).length }));

        return counts;
    }, [expandedProspects]);

    const handleStatusClick = val => {
        setSelectedStatus(val);
        setSelectedIds([]);
    }

    const handleProfileClick = val => {
        setSelectedProfile(val);
        setSelectedIds([]);
    }

    const handleTriageClick = val => {
        setSelectedTriage(val);
        setSelectedIds([]);
    }

    const handleOwnerClick = ids => {

        setSelectedOwnerIds(ids);
        setSelectedIds([]);
    }

    const toggleCheckbox = (id) => {
        if (id === null) {
            if (selectedIds.length < filteredProspectGroups.length)
                setSelectedIds(filteredProspectGroups.flatMap(p => p.prospects).map(p => p.id));
            else
                setSelectedIds([])
        } else {
            if (selectedIds.includes(id))
                setSelectedIds(selectedIds.filter(x => x !== id));
            else
                setSelectedIds([...selectedIds, id]);
        }
    }

    const closeMenu = () => {
        setAnchorEl();
    }

    const recommended = useCallback((industry, employeeProfile) => {
        if (!mappings || !employeeProfile)
            return [];

        return mappings.filter(x => x.industry === industry && x.employeeProfile === employeeProfile);
    }, [mappings]);

    const auditTooltip = useCallback((audits, ownerId) => {
        let userInfo = <div>{user(ownerId)?.name}</div>;

        if (audits.length === 0)
            return userInfo;

        let title = (
            <div style={{ display: 'flex', flexDirection: 'column' }} display="flex" flexDirection="column">
                {audits.map((a, index) =>
                    <div key={index}>Assigned to {user(a.newValue).name} by {a.actionedBy} on {format(new Date(a.createdOn), "dd/MM/yy")}</div>
                )}
            </div>
        );

        return <Tooltip classes={{ tooltip: classes.noMaxWidth }} title={title}>{userInfo}</Tooltip>
    }, [classes.noMaxWidth, user]);

    const assignToList = useCallback(() => {

        const roles = {
            "00E0Y000000dhmhUAA": "BDE",
            "00E0Y000000dopxUAA": "BM",
            "00ESg0000004ThlMAE": "CMO"

        }
        let items = [];

        Object.keys(roles).forEach(id => {
            items.push(<ListSubheader key={id}>{roles[id]}</ListSubheader>);
            usersForRole(id).forEach(u => items.push(<MenuItem onClick={() => assignUser(u.id)} key={u.id}> {u.name}</MenuItem>))
        });

        return items;

    }, [assignUser, usersForRole])

    const jobTitleCsvHeaders = [
        { label: "Job Title", key: "title" },
        { label: "Is SDM?", key: "isSDM" }
    ];

    const prospectCsvHeaders = [
        { label: "Email", key: "email" },
        { label: "Triage Status", key: "triage" }
    ];

    const jobTitleCsvData = useMemo(() => {

        if (!jobTitles)
            return [];

        let known = jobTitles.map(x => x.title);
        let prospectJobTitles = expandedProspects.map(p => p.jobTitle?.toUpperCase());

        var data = prospectJobTitles.filter(x => !known.includes(x)).map(x => ({
            title: x.replace("\"", "\"\""),
            isSDM: ""
        }));

        return data;

    }, [jobTitles, expandedProspects]);   

    return (
        <Box display="flex" flexDirection="column" className={classes.page}>
            {form &&
                <Box display="flex">
                    <Box>{form.name}</Box>
                    <Box><CloseIcon style={{ cursor: 'pointer' }} onClick={clear} /></Box>
                </Box>
            }
            {list &&
                <Box display="flex">
                    <Box>{list.name}</Box>
                    <Box><CloseIcon style={{ cursor: 'pointer' }} onClick={clear} /></Box>
                </Box>
            }
            {(!prospectGroups || isFetching) &&
                <h2 style={{ margin: '10px' }}>Loading ...</h2>
            }
            {
                !isFetching && prospectGroups && prospectGroups.length === 0 &&
                <h2 style={{ margin: '10px' }}>No entries</h2>
            }
            {!isFetching && prospectGroups && prospectGroups.length > 0 &&
                <>
                    <Box display="flex" style={{ marginTop: '10px' }} alignItems="center" gridGap="10px" flexWrap="wrap">

                        <Typography variant="h6">{prospectGroups.length} groups - {filteredProspectGroups.length} filtered - <span style={{ fontSize: '1.3em' }}>{selectedIds.length}</span> selected</Typography>
                        {selectedIds.length > 0 &&
                            <>
                                <Button
                                    disabled={saving}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => setAnchorEl(e.currentTarget)}>
                                    Assign To
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={closeMenu}
                                >
                                    {assignToList()}
                                </Menu>
                                <FormControl variant="outlined" margin="dense" className={classes.formControl}>
                                    <InputLabel shrink>Task Due</InputLabel>
                                    <Select
                                        input={<OutlinedInput notched label="TaskDue" />}
                                        value={taskDays}
                                        onChange={(e) => setTaskDays(e.target.value)}
                                    >
                                        <MenuItem value={0}>Today</MenuItem>
                                        <MenuItem value={1}>Next working day</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        }
                        <Button
                            style={{ whiteSpace: 'nowrap' }}
                            disabled={saving}
                            variant="contained"
                            color="primary"
                            onClick={() => setSelectedIds(filteredProspectGroups.slice(0, 20).map(p => p.id))}>
                            Top 20
                        </Button>
                        <Box flexGrow={1}></Box>
                        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
                            <InputLabel shrink>Status</InputLabel>
                            <Select
                                input={<OutlinedInput notched label="Status" />}
                                value={selectedStatus}
                                onChange={(e) => handleStatusClick(e.target.value)}
                            >
                                <MenuItem value=""><em>all</em></MenuItem>
                                {statusCounts.map(c => <MenuItem key={c.status} value={c.status}>{c.status} ({c.count})</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
                            <InputLabel shrink>Profile</InputLabel>
                            <Select
                                input={<OutlinedInput notched label="Profile" />}
                                value={selectedProfile}
                                onChange={(e) => handleProfileClick(e.target.value)}
                            >
                                <MenuItem value=""><em>all</em></MenuItem>
                                {profileCounts.map(c => <MenuItem key={c.profile} value={c.profile || "unknown"}>{c.profile || "unknown"} ({c.count})</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
                            <InputLabel shrink>Triage</InputLabel>
                            <Select
                                input={<OutlinedInput notched label="Triage" />}
                                value={selectedTriage}
                                onChange={(e) => handleTriageClick(e.target.value)}
                            >
                                <MenuItem value=""><em>all</em></MenuItem>
                                {stageCounts.map(c => <MenuItem key={c.triage} value={c.triage}>{c.triage} ({c.count})</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
                            <InputLabel shrink>Owners</InputLabel>
                            <Select
                                multiple
                                renderValue={(selected) => selected.map(x => user(x)?.name || "UNASSIGNED").join(', ')}
                                input={<OutlinedInput notched label="Owners" />}
                                value={selectedOwnerIds}
                                onChange={(e) => handleOwnerClick(e.target.value)}
                            >
                                {userCounts.map(c =>
                                    <MenuItem key={c.user.id} value={c.user.id}>
                                        <Checkbox checked={selectedOwnerIds.includes(c.user.id)} />
                                        <ListItemText primary={`${c.user.name} (${c.count})`} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        {jobTitleCsvData.length > 0 &&
                            <CSVLink
                                headers={jobTitleCsvHeaders}
                                data={jobTitleCsvData}
                                filename={`JobTitles.csv`}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                >
                                    Export Unknown Job Titles
                                </Button>
                            </CSVLink>
                        }
                        {expandedProspects.length > 0 &&
                            <CSVLink
                                headers={prospectCsvHeaders}
                                data={expandedProspects}
                                filename={`Prospects.csv`}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                >
                                    Export Prospects
                                </Button>
                            </CSVLink>
                        }
                        {!list && (
                            <FormControl variant="outlined" margin="dense">
                                <InputLabel>Time range</InputLabel>
                                <Select
                                    label="Time range"
                                    value={previousDays}
                                    onChange={(e) => setPreviousDays(e.target.value)}
                                >
                                    <MenuItem value={0}>Today only</MenuItem>
                                    <MenuItem value={1}>Include yesterday</MenuItem>
                                    <MenuItem value={2}>Previous 2 days</MenuItem>
                                    <MenuItem value={3}>Previous 3 days</MenuItem>
                                    <MenuItem value={7}>Previous 7 days</MenuItem>
                                    <MenuItem value={30}>Previous 30 days</MenuItem>
                                    <MenuItem value={60}>Previous 60 days</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <PerfectScrollbar className={classes.container} options={{ minScrollbarLength: 150 }}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        count={filteredProspectGroups.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(e, newPage) => setPage(newPage)}
                                    />
                                </TableRow>
                                <TableRow>
                                    {tableHeadings
                                        .filter((th) => th.display)
                                        .map((th, index) => (
                                            <TableCell key={index}>
                                                {th.name === "" ? (
                                                    <Checkbox
                                                        style={{ padding: 0 }}
                                                        disabled={saving}
                                                        onChange={() => toggleCheckbox(null)}
                                                        checked={selectedIds.length > 0 && selectedIds.length === filteredProspectGroups.flatMap(g => g.prospects).length}
                                                        indeterminate={selectedIds.length > 0 && selectedIds.length < filteredProspectGroups.flatMap(g => g.prospects).length}
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <Button
                                                        disabled={!th.sortKey}
                                                        className={classes.button}
                                                        onClick={th.sortKey && (() => toggleSortBy(th))}
                                                    >
                                                        {th.name} {!sortAscending && th.sortKey === sortColumn && <ArrowDropDown />}
                                                        {sortAscending && th.sortKey === sortColumn && <ArrowDropUp />}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            {displayedProspectGroups.map(g =>
                                <TableBody className={g.prospects.length > 1 ? classes.rowGroup : null} key={g.id}>
                                    {g.prospects.map(p =>
                                        <TableRow key={p.id} style={p.error ? { background: '#ff474c' } : {}}>
                                            <TableCell>{p.status !== "DELETED" &&
                                                <Checkbox
                                                    style={{ padding: 0 }}
                                                    disabled={saving}
                                                    onChange={() => toggleCheckbox(p.id)}
                                                    checked={selectedIds.includes(p.id)}
                                                    color="primary"
                                                />}
                                            </TableCell>
                                            <TableCell>
                                                {p.firstName} {p.lastName}<br />
                                                <span style={{ fontSize: '0.8em' }}>{p.email}</span><br />
                                                <span style={{ fontSize: '0.65em' }}>{p.contactStatus}</span>
                                                {p.contactSubStatus && <span style={{ fontSize: '0.65em' }}> / {p.contactSubStatus}</span>}
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ display: 'flex' }}>
                                                    <div>{sdmIcon(p.jobTitle)}</div>
                                                    <div>{p.jobTitle}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {p.company}<br />
                                                <span style={{ fontSize: '0.8em' }}>{p.industry}</span><br />
                                                <span style={{ fontSize: '0.65em' }}>{p.accountType || "BLANK"} / {p.accountStatus || "BLANK"}</span>
                                            </TableCell>                             
                                            <TableCell>{p.employeeProfile}{p.employeeProfile && p.employeeCount != null && <br />}<span style={{ fontSize: '0.8em' }}>{p.employeeCount}</span></TableCell>
                                            {p.error
                                                ? <Tooltip title={p.error}><TableCell>{p.score}</TableCell></Tooltip>
                                                : <TableCell>{p.score}</TableCell>
                                            }
                                            <TableCell>{format(new Date(p.lastActivity), "dd/MM/yy HH:mm")}<br /><span style={{ fontSize: '0.8em' }}>{p.recentInteraction}</span></TableCell>
                                            {!form && !list && (
                                                <TableCell>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {p.formIds?.map(id => <div key={id} style={{ fontSize: '0.8em' }}>{getFormName(id)}</div>)}
                                                    </div>
                                                </TableCell>
                                            )}
                                            <TableCell>{p.formScore}</TableCell>
                                            {p.triageInfo
                                                ? <Tooltip title={p.triageInfo}><TableCell>{p.triage}<br /><span style={{ fontSize: '0.8em' }}>{p.status}</span></TableCell></Tooltip>
                                                : <TableCell>{p.triage}<br /><span style={{ fontSize: '0.8em' }}>{p.status}</span></TableCell>
                                            }
                                            <TableCell>{p.taskDueDate && format(new Date(p.taskDueDate), "dd/MM/yy")}</TableCell>
                                            <TableCell style={{ backgroundColor: p.audits.length > 0 ? '#eeeeee' : 'inherit' }}>
                                                {auditTooltip(p.audits, p.salesforceOwnerId)}
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {recommended(p.industry, p.employeeProfile).map(entry => <div key={entry.salesforceUserId} style={{ fontSize: '0.8em' }}>{user(entry.salesforceUserId).name}</div>)}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </PerfectScrollbar>
                </>
            }
        </Box>
    )
};

export default Prospects;
